<template>
    <mercur-card class="mb-4 mt-n4">
        <h3>User Info</h3>
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="col-6">
                    <mercur-input v-model="form.firstName" id="firstName" :class="getValidationClass($v, 'firstName')">
                        First Name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.firstName.required">First name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input v-model="form.lastName" id="lastName" :class="getValidationClass($v, 'lastName')">
                        Last Name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.lastName.required">Last name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input v-model="form.username" type="email" id="userName" :class="getValidationClass($v, 'username')">
                        Username
                        <template #note>
                            <span class="form-error" v-if="!$v.form.username.required">Username is required</span>
                        </template>
                    </mercur-input>
                </div>
            </div>
            <br>
            <div class="text-right">
                <mercur-button class="btn" :to="{ name: 'CatalogWrapper' }">Discard Changes</mercur-button>
                <mercur-button type="submit" class="btn btn-primary" :disabled="loading">Save</mercur-button>
            </div>
        </form>
    </mercur-card>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ProfileInfo',
    mixins: [ FormMixin ],
    data () {
        return {
            form: {},
        }
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.AGENTS.UPDATE.replace('{agentId}', this.$store.state.auth.user.accountId)
        },
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            username: {
                required,
            },
        },
    },
    methods: {
        submitForm () {
            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Account was updated. Logout and Login to see these changes.',
                })
            })
        },
    },
    created () {
        this.form = {
            firstName: this.$store.state.auth.user.firstName,
            lastName: this.$store.state.auth.user.lastName,
            username: this.$store.state.auth.user.username,
        }
    },
}
</script>

<template>
    <div class="c-shop-settings">
        <v-title title="Profile"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Profile Settings</h1>
                </div>
            </div>
        </title-box>
        <template v-if="hasPermission('MerchantCentral/getProfile')">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-8">
                        <profile-info></profile-info>
                        <mercur-card>
                            <h3>Set new password</h3>
                            <form @submit.prevent="submitForm">
                                <div class="row">
                                    <div class="col-6">
                                        <mercur-input v-model="form.password" type="password" :class="getValidationClass($v, 'password')" id="password">
                                            New password
                                            <template #note>
                                                <span class="form-error" v-if="!$v.form.password.required">Password is required</span>
                                            </template>
                                        </mercur-input>
                                    </div>
                                    <div class="col-6">
                                        <mercur-input v-model="form.passwordRepeat" type="password" :class="getValidationClass($v, 'passwordRepeat')" id="passwordRepeat">
                                            New password again
                                            <template #note>
                                                <span class="form-error" v-if="!$v.form.passwordRepeat.required">Password repeat is required</span>
                                                <span class="form-error" v-else-if="!$v.form.passwordRepeat.sameAsPassword">Passwords are not matching</span>
                                            </template>
                                        </mercur-input>
                                    </div>
                                </div>
                                <br>
                                <div class="text-right">
                                    <mercur-button class="btn" :to="{ name: 'CatalogWrapper' }">Discard Changes</mercur-button>
                                    <mercur-button v-if="hasPermission('MerchantCentral/updatePassword')" class="btn btn-primary" type="submit" :disabled="loading">Save</mercur-button>
                                </div>
                            </form>
                        </mercur-card>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            Not allowed to see this view
        </template>
    </div>
</template>

<script>

import ProfileInfo from '@/components/elements/profile/Info'
import FormMixin from '@/mixins/Form'
import CONFIG from '@root/config'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
    name: 'ProfileView',
    components: { ProfileInfo },
    mixins: [ FormMixin ],
    data () {
        return {
            form: {},
        }
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.AGENTS.PASSWORD.UPDATE.replace('{agentId}', this.$store.state.auth.user.accountId)
        },
    },
    validations: {
        form: {
            password: {
                required,
            },
            passwordRepeat: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
    },
    methods: {
        submitForm () {
            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Password was updated. Logout and Login to see these changes.',
                })
            })
        },
    },

}
</script>

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function load (view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            name: 'LoginView',
            component: load('LoginView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/',
            name: 'HomeView',
            redirect: '/catalog/catalog',
        },
        {
            path: '/profile',
            name: 'ProfileView',
            component: load('ProfileView'),
            props: {
                breadcrumbElement: {
                    name: 'Profile',
                },
            },
        },
        {
            path: '/password-recover/:RECOVERY_TOKEN',
            name: 'PasswordRecover',
            component: load('PasswordRecoverView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/shop-config',
            props: {
                breadcrumbElement: {
                    name: 'Shop Config',
                },
            },
            component: load('ShopConfigView'),
            children: [
                {
                    path: '',
                    name: 'ShopConfigView',
                    redirect: '/shop-config/general',
                },
                {
                    path: 'finance',
                    name: 'ShopConfigFinance',
                    component: load('shop-config/Finance'),
                    props: {
                        breadcrumbElement: {
                            name: 'finance',
                        },
                    },
                },
                {
                    path: 'orders',
                    name: 'ShopConfigOrders',
                    component: load('shop-config/Orders'),
                    props: {
                        breadcrumbElement: {
                            name: 'orders',
                        },
                    },
                },
                {
                    path: 'content',
                    name: 'ShopConfigContent',
                    component: load('shop-config/Content'),
                    props: {
                        breadcrumbElement: {
                            name: 'content',
                        },
                    },
                },
                {
                    path: 'artwork',
                    name: 'ShopConfigArtwork',
                    component: load('shop-config/Artwork'),
                    props: {
                        breadcrumbElement: {
                            name: 'artwork',
                        },
                    },
                },
                {
                    path: 'general',
                    name: 'ShopConfigGeneral',
                    component: load('shop-config/General'),
                    props: {
                        breadcrumbElement: {
                            name: 'general',
                        },
                    },
                },
                {
                    path: 'shipment',
                    name: 'ShopConfigShipment',
                    component: load('shop-config/Shipment'),
                    props: {
                        breadcrumbElement: {
                            name: 'shipment',
                        },
                    },
                },
                {
                    path: 'customer360',
                    name: 'ShopConfigCustomer360',
                    component: load('shop-config/Customer360'),
                    props: {
                        breadcrumbElement: {
                            name: 'customer360',
                        },
                    },
                },
                {
                    path: 'presta',
                    name: 'ShopConfigPresta',
                    component: load('shop-config/Presta'),
                    props: {
                        breadcrumbElement: {
                            name: 'presta',
                        },
                    },
                },
            ],
        },
        {
            path: '/agents',
            props: {
                breadcrumbElement: {
                    name: 'agents',
                },
            },
            component: load('wrappers/AgentsWrapper'),
            children: [
                {
                    path: '',
                    name: 'AgentsWrapper',
                    component: load('AgentsView'),
                },
                {
                    path: 'add',
                    name: 'AddAgent',
                    component: load('agent/AddAgent'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':agentId',
                    name: 'EditAgent',
                    component: load('agent/EditAgent'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
                {
                    path: ':agentId/permissions',
                    name: 'EditAgentPermissions',
                    component: load('agent/EditAgentPermissions'),
                    props: {
                        breadcrumbElement: {
                            name: 'permissions',
                        },
                    },
                },
            ],
        },
        {
            path: '/products',
            component: load('wrappers/ProductsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'products',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ProductsWrapper',
                    component: load('ProductsView'),
                },
                {
                    path: 'attributes',
                    name: 'AttributesView',
                    component: load('products/AttributesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'attributes',
                        },
                    },
                },
                {
                    path: 'catalog',
                    redirect: {
                        name: 'CatalogWrapper',
                    },
                },
            ],
        },
        {
            path: '/catalog',
            component: load('products/catalog/CatalogWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'catalog',
                },
            },
            meta: {
                style: {
                    backgroundColor: '#f8f8f8',
                },
            },
            children: [
                {
                    path: 'catalog',
                    name: 'CatalogWrapper',
                    component: load('products/catalog/CatalogOverview'),
                    props: true,
                },
                {
                    path: 'marketplace',
                    name: 'CatalogWrapperMarket',
                    component: load('products/catalog/CatalogOverview'),
                    props: {
                        overviewType: 'marketplace',
                        breadcrumbElement: {
                            name: 'marketplace',
                        },
                    },
                },
                {
                    path: 'product/:catalogProductId/:productVariantId?',
                    component: load('products/catalog/CatalogProductView'),
                    props: true,
                    children: [
                        {
                            path: '',
                            name: 'CatalogProductView',
                            component: load('products/catalog/CatalogProductEdit'),
                            props: true,
                        },
                    ],
                },
            ],
        },
        {
            path: '/roles',
            component: load('wrappers/RolesWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'roles',
                },
            },
            children: [
                {
                    path: '',
                    name: 'RolesWrapper',
                    component: load('Roles'),
                },
                {
                    path: 'add',
                    name: 'AddRole',
                    component: load('roles/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':roleId',
                    name: 'EditRole',
                    component: load('roles/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/permissions',
            component: load('wrappers/PermissionsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'permissions',
                },
            },
            children: [
                {
                    path: '',
                    name: 'PermissionsWrapper',
                    component: load('Permissions'),
                },
                {
                    path: 'add',
                    name: 'AddPermission',
                    component: load('permissions/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':permissionId',
                    name: 'EditPermission',
                    component: load('permissions/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/smartspy',
            component: load('wrappers/SmartSpyWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'smartspy',
                },
            },
            children: [
                {
                    path: '',
                    name: 'SmartSpyWrapper',
                    component: load('SmartSpy'),
                },
            ],
        },
        {
            path: '/fasteditor',
            component: load('wrappers/FastEditorWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'fasteditor',
                },
            },
            children: [
                {
                    path: '',
                    name: 'FastEditorWrapper',
                    component: load('FastEditor'),
                },
            ],
        },
        {
            path: '/applications',
            component: load('wrappers/ApplicationsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'applications',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ApplicationsWrapper',
                    component: load('Applications'),
                },
                {
                    path: 'add',
                    name: 'AddApplication',
                    component: load('applications/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':applicationId',
                    name: 'EditApplication',
                    component: load('applications/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/groups',
            component: load('wrappers/GroupsWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'groups',
                },
            },
            children: [
                {
                    path: '',
                    name: 'GroupsWrapper',
                    component: load('Groups'),
                },
                {
                    path: 'add',
                    name: 'AddGroup',
                    component: load('groups/Add'),
                    props: {
                        breadcrumbElement: {
                            name: 'add',
                        },
                    },
                },
                {
                    path: ':groupId',
                    name: 'EditGroup',
                    component: load('groups/Edit'),
                    props: {
                        breadcrumbElement: {
                            name: '',
                        },
                    },
                },
            ],
        },
        {
            path: '/vat-config',
            component: load('wrappers/VatConfigWrapper'),
            props: {
                breadcrumbElement: {
                    name: 'vat config',
                },
            },
            children: [
                {
                    path: 'vat-profiles',
                    name: 'VatConfigWrapper',
                    component: load('vat-config/VatProfiles'),
                    props: {
                        breadcrumbElement: {
                            name: 'vat profiles',
                        },
                    },
                },
                {
                    path: 'company-profiles',
                    name: 'CompanyProfiles',
                    component: load('vat-config/CompanyProfiles'),
                    props: {
                        breadcrumbElement: {
                            name: 'company profiles',
                        },
                    },
                },
            ],
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

import auth from './auth'
import loading from './loading'
import locale from './locale'
import session from './session'
import shop from './shop'
import customer from './customer'
import shopconfig from './shopconfig'
import products from './products'
import catalog from './catalog'
import agents from './agents'

export default {
    auth,
    shop,
    loading,
    locale,
    session,
    customer,
    shopconfig,
    products,
    catalog,
    agents,
}

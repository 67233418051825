/* global env */
export default {
    DEBUG: env.VUE_APP_DEBUG === 'true',
    VUE_APP_AG_GRID_LICENSE_KEY: env.VUE_APP_AG_GRID_LICENSE_KEY,
    API: {
        URL: env.VUE_APP_API_URL,
        PREFIX: {
            SEGMENT: 'track/',
        },
        ROUTES: {
            ACTIONS: {
                OVERVIEW: '/actions/overview',
                GET_ALLOWED: '/actions/{accountId}/get-allowed-actions',
            },
            ACCOUNT: {
                PASSWORD: {
                    RECOVER_REQUEST: '/auth/password/recovery-request',
                    RESET: '/auth/password/recovery',
                },
            },
            AGENTS: {
                OVERVIEW: '/agents/overview',
                ADD: '/agents/add',
                GET: '/agents/{agentId}',
                UPDATE: '/agents/update/{agentId}',
                REVOKE: '/agents/revoke/{agentId}',
                REACTIVATE: '/agents/reactivate/{agentId}',
                PASSWORD: {
                    UPDATE: '/agents/password/update/{agentId}',
                },
            },
            APPLICATIONS: {
                OVERVIEW: '/applications/overview',
                GET_ALLOWED: '/applications/{accountId}/get-allowed-applications',
                MANAGEMENT_OVERVIEW: '/applications/managementoverview',
                GET: '/applications/get/{applicationId}',
                ADD: '/applications/add',
                UPDATE: '/applications/update',
                ENABLE_DISABLE: '/applications/enableDisable',
            },
            APPLICATIONS_GROUPS: {
                OVERVIEW: '/applicationsgroups/overview',
                ADD: '/applicationsgroups/add',
                GET: '/applicationsgroups/{groupId}',
                UPDATE: '/applicationsgroups/update',
                REVOKE: '/applicationsgroups/revoke',
            },
            CARRIERS: {
                GET_CURRENCY_CONVERSION_RATES: '/carriers/conversionrates',
            },
            CATALOG: {
                ADD_PRODUCT_TO_CATALOG: '/catalog/addProductToCatalog',
                SET_CATALOG_PRODUCT_APPLICATIONS: '/catalog/applications/set',
                ATTRIBUTES: {
                    OVERVIEW: '/catalog/attributes/overview',
                },
                CONTENT: {
                    CREATE: '/catalog/content/create',
                },
                DELETE_CATALOG_PRODUCT: '/catalog/delete/{catalogProductId}',
                FAQ: {
                    OVERVIEW: '/catalog/overviewFaq',
                },
                LIST: '/catalog/list',
                GET: '/catalog/get/{catalogProductId}',
                CHECK_PRODUCT_VARIANTS_ATTRIBUTES: '/catalog/checkProductVariantsAttributes/{catalogProductId}',
                GET_AGREEMENTS_FOR_APPLICATION: '/catalog/agreements/getByApplication',
                GET_ATTRIBUTES_FOR_CATALOG_BY_PRESET: '/catalog/attributes/getAvailableAttributes',
                IMAGES: {
                    GET_PREVIEW_URL: '/catalog/images/{catalogProductId}/preview',
                    GET_MARKETPLACE_PREVIEW_URLS: '/catalog/marketplaceProducts/images/preview',
                },
                LIST_CATALOG_PRODUCTS: '/catalog/overview',
                LIST_MARKETPLACE_PRODUCTS: '/catalog/overviewMarketplace',
                SUBMISSION_SPECIFICATIONS: {
                    OVERVIEW: '/catalog/overviewSubmissionSpecifications',
                },
                TAGS: {
                    OVERVIEW: '/catalog/overviewTags',
                },
                UPDATE: '/catalog/update/{catalogProductId}/{section}',
                VARIANTS: {
                    LIST: '/catalog/variants/list',
                    LIST_BY_CATALOG_PRODUCT: '/catalog/variants/{catalogProductId}/list',
                    CLONE: '/catalog/variants/clone/{productVariantId}',
                    CREATE: '/catalog/variants/create',
                    DELETE: '/catalog/variants/delete/{productVariantId}',
                    GET: '/catalog/variants/get/{productVariantId}',
                    PUBLISH: '/catalog/variants/publish/{productVariantId}',
                    UNPUBLISH: '/catalog/variants/unpublish/{productVariantId}',
                    REINDEX: '/catalog/variants/reindex/{productVariantId}',
                    UPDATE: '/catalog/variants/update/{productVariantId}',
                    PRODUCT_SETTINGS: {
                        LIST: '/catalog/variants/{productVariantId}/settings',
                        CREATE: '/catalog/variants/{productVariantId}/settings/create',
                        UPDATE: '/catalog/variants/{productVariantId}/settings/{catalogProductSettingId}/update',
                        REMOVE: '/catalog/variants/{productVariantId}/settings/{catalogProductSettingId}/delete',
                    },
                    OVERVIEW_IDS_AND_NAMES: '/catalog/variants/overviewidsandnames',
                    APPLICATIONS: {
                        PUBLISH: '/catalog/variants/applications/publish',
                        UNPUBLISH: '/catalog/variants/applications/unpublish',
                    },
                },
                GENERATE_PRODUCT_VARIANTS_FILE: '/catalog/generateProductVariantsFile'
            },
            CATEGORIES: {
                OVERVIEW: '/category/overview',
                ADD: '/category/add',
                UPDATE: '/category/update',
                REMOVE: '/category/remove/{categoryId}',
                ADD_PRODUCT: '/category/product/{categoryId}/{catalogId}/add',
                REMOVE_PRODUCT: '/category/product/{categoryId}/{catalogId}/remove',
            },
            FAST_EDITOR: {
                OVERVIEW: '/fasteditor/overview',
            },
            CUSTOMERS: {
                OVERVIEW: '/customers/overview/{agentId}',
                ADD: '/customers/add',
                UPDATE: '/customers/update/{customerId}',
                REVOKE: '/customers/revoke/{customerId}',
                REACTIVATE: '/customers/reactivate/{customerId}',
                IMPERSONATE: '/customers/impersonate/{customerId}',
                RESET_PASSWORD: '/customers/password/reset-request',
                GET: {
                    OVERVIEW: '/customers/{customerId}',
                    ADDRESSES: '/customers/{customerId}/{organisationId}/addresses',
                },
                ADDRESS: {
                    ADD: '/customers/{customerId}/{organisationId}/address/add',
                    UPDATE: '/customers/{customerId}/{organisationId}/address/update',
                    REMOVE: '/customers/{customerId}/{organisationId}/address/{addressId}/remove',
                    SET_DEFAULT_BILLING: '/customers/{customerId}/address/{addressId}/setdefaultbillingaddress',
                    SET_DEFAULT_SHIPPING: '/customers/{customerId}/address/{addressId}/setdefaultshippingaddress',
                },
                GROUPS: {
                    OVERVIEW: '/customers/groups/overview',
                    ADD: '/customers/groups/add',
                    GET: '/customers/groups/{groupId}',
                    UPDATE: '/customers/groups/update',
                    REVOKE: '/customers/groups/revoke',
                    LIST_PRICE_GROUPS: '/customers/groups/pricegroups/overview',
                },
                CONFIGURATIONS: {
                    ADD: '/customers/configurations/add',
                    GET_AVAILABLE_SYSTEM_CONFIGURATIONS: '/customers/configurations/available_system_configurations',
                    APPLICATION_ACCOUNT_CONFIGURATION_SCHEMAS: '/customers/configurations/application_account_configuration_schemas',
                    CONFIGURATION_SCHEMAS: '/customers/configurations/configuration_schemas',
                    FILTERED_OVERVIEW: '/customers/configurations/filtered_overview?configurationType={configurationType}',
                    GET: {
                        OVERVIEW: '/customers/configurations/{accountConfigurationId}',
                    },
                    OVERVIEW: '/customers/configurations/overview',
                    DELETE: '/customers/configurations/delete?accountConfigurationId={accountConfigurationId}',
                    UPDATE: '/customers/configurations/update',
                },
                NOTES: {
                    OVERVIEW: '/customers/{customerId}/notes/overview',
                    ADD: '/customers/{customerId}/{agentId}/notes/add',
                    REMOVE: '/customers/{customerId}/{noteId}/notes/remove',
                },
            },
            LOGIN: '/auth/login',
            PERMISSIONS: {
                OVERVIEW: '/permissions/overview',
                ADD: '/permissions/add',
                GET: '/permissions/{permissionId}',
                UPDATE: '/permissions/update',
                REVOKE: '/permissions/revoke',
            },
            SMART_SPY: {
                OVERVIEW: '/smartspy/overview',
                CREATE_TASK: '/smartspy/create-task',
                RETRY_TASK: '/smartspy/retry-task',
                LIST_HISTORY: '/smartspy/history',
            },
            PRODUCTS_MANAGEMENT: {
                GET_AGREEMENTS: '/productsmanagement/agreements/overview',
                GET_PRODUCT_DEFINITIONS: '/productsmanagement/agreements/definitions/{agreementId}',
                GET_ATTRIBUTES_BY_SHOP_AND_PRODUCT: '/productsmanagement/attributes/product/overview/{productHash}/{shopHash}',
                GET_ATTRIBUTES_BY_SHOP: '/productsmanagement/attributes/overview/{shopHash}',
                GET_ATTRIBUTES_FOR_FUNNEL: '/productsmanagement/attributes/product/funnel/{productHash}/{shopHash}',
                LIST_PRODUCT_DEFINITION_MAPPINGS: '/productsmanagement/agreements/definitions/mappings/{productHash}/{shopHash}',
                LIST_PRODUCT_DEFINITION_MAPPINGS_OVERVIEW: '/productsmanagement/agreements/definitions/mappings/overview',
                LIST_PRODUCT_LABELS_PER_SHOP: '/productsmanagement/agreements/definitions/labels/{shopHash}',
                SET_PRODUCT_MAPPING: '/productsmanagement/agreements/definitions/mappings/{productHash}/{agreementId}/{shopHash}/set',
                SET_ATTRIBUTE_OPTIONS_MAPPING: '/productsmanagement/attributes/definitions/mappings/{agreementId}/{shopHash}/set',
                LIST_ATTRIBUTE_OPTIONS_DEFINITION_MAPPINGS: '/productsmanagement/attributes/definitions/mappings/{agreementId}/{shopHash}/overview',
                REMOVE_PRODUCT_MAPPING: '/productsmanagement/agreements/definitions/mappings/{productHash}/{agreementId}/remove',
                REMOVE_ATTRIBUTE_OPTIONS_MAPPING: '/productsmanagement/attributes/definitions/mappings/{agreementId}/remove',
                LIST_PRODUCTS: '/productsmanagement/products/overview',
                GET_TURNAROUNDS: '/productsmanagement/{shopHash}/{productHash}/turnarounds',
                GET_PRODUCT_TURNAROUNDS: '/productsmanagement/{shopHash}/{productHash}/product-turnarounds',
                LIST_CATALOG_PRODUCT_VARIANTS: '/productsmanagement/products/catalog-variants',
            },
            ROLES: {
                OVERVIEW: '/roles/overview',
                GET: '/roles/{roleId}',
                ADD: '/roles/add',
                UPDATE: '/roles/update',
                REVOKE: '/roles/revoke',
            },
            SEARCH: {
                OVERVIEW: '/search/overview/{agentId}',
            },
            SHOP_CONFIG: {
                OVERVIEW: '/shopconfig/overview/{category}',
                UPDATE: '/shopconfig/update',
                CREATE: '/shopconfig/create',
                REMOVE: '/shopconfig/remove/{configurationId}',
                SYNC: '/shopconfig/sync',
            },
            SUPPLIER: {
                OVERVIEW: '/supplier/overview',
                LIST_BY_SHIP_TO_COUNTRY: '/supplier/listbycountry/{country}',
                GET_TURNAROUND_CALCULATION: '/supplier/getturnaroundcalculation/{turnaroundHash}',
            },
            VAT_CONFIG: {
                VAT_PROFILES: {
                    LIST: '/vatconfig/vatprofiles/list',
                    REMOVE: '/vatconfig/vatprofiles/{vatProfileId}/remove',
                    UPDATE: '/vatconfig/vatprofiles/update',
                    CREATE: '/vatconfig/vatprofiles/create',
                    LIST_FOR_APPLICATION: '/vatconfig/vatprofiles/{applicationId}/list',
                    FOR_PRODUCT_VARIANT: '/vatconfig/vatprofilesforproductvariant/{productVariantId}',
                },
                COMPANY_PROFILES: {
                    LIST: '/vatconfig/companyprofiles/list',
                    REMOVE: '/vatconfig/companyprofiles/{companyProfileId}/remove',
                    UPDATE: '/vatconfig/companyprofiles/update',
                    CREATE: '/vatconfig/companyprofiles/create',
                },
            },
        },

        REQUEST_DEFAULT: {
            request: {
                startRow: 0,
                endRow: 9999999,
                rowGroupCols: [],
                valueCols: [],
                pivotCols: [],
                pivotMode: false,
                groupKeys: [],
                filterModel: {},
                sortModel: [],
            },
        },
    },

    NAVIGATION: {
        LOGIN_PATH: '/login',
        LOGOUT_PATH: '/logout',
    },

    COOKIES: {
        TOKEN: {
            KEY: 'token',
        },
        LOCALE: {
            KEY: 'locale',
        },
        SESSION: {
            KEY: 'session',
        },
        SHOP: {
            KEY: 'shop',
        },
        ASSIGNED_ORDER: {
            KEY: 'assigned_order',
        },
    },
    SHOP_UNIQUE_VIEWS: ['CustomerView', 'AddOrder', 'AddQuote', 'EditQuote'],
    ARTWORK_GRADES: ['INTERMEDIATE', 'ADVANCED', 'EXPERT', 'MASTER', 'SPECIALIST'],
}

<template>
    <div>
        <alert type="danger" v-if="isInvalidBundleError">
            Please fill all the template fields and try again.
        </alert>
        <alert type="danger" v-if="errorMessage && !isDirty">
            {{errorMessage}}
        </alert>

        <catalog-product-setting-eco-cart
            v-if="type==='EcoCart'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-eco-cart>

        <catalog-product-setting-clothing
            v-if="type==='Clothing'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-clothing>

        <catalog-product-setting-claims
            v-if="type==='Claims'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-claims>

        <catalog-product-setting-fast-editor
            v-if="type==='FastEditor'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-fast-editor>

        <catalog-product-setting-sample
            v-if="type==='Sample'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-sample>

        <catalog-product-setting-mongo-db
            v-if="type==='MongoDb'"
            :value="catalogProductSettings.configuration[this.type]"
        ></catalog-product-setting-mongo-db>

        <div class="d-flex" v-if="!isEditing">
            <mercur-button
                class="btn btn-yellow ml-auto"
                :disabled="!isDirty"
                @click="saveBundleSelection()"
            >Add configuration
            </mercur-button>
        </div>
        <div class="d-flex" v-if="isEditing">
            <mercur-button
                class="btn btn-gray-100 ml-auto"
                @click="discardChanged()"
                :disabled="!isDirty || isSaving"
            >Discard changes</mercur-button>
            <mercur-button
                class="btn btn-yellow ml-lg-1"
                :disabled="!isDirty"
                @click="saveBundleSelection()"
            >Update configuration
            </mercur-button>
        </div>
        <template v-if="hasPermission('MerchantCentral/listEditorConfigurationSettings')">
            <div>
                <h4>List of {{type}} settings</h4>
                <div class="row mt-3">
                    <div class="col-12">
                        <pagination :pagination="pagination" @change="changeAmountOfItems"/>
                    </div>
                </div>

                <merchant-data-table
                    :options="mergedOptions"
                    :url="url"
                    :quickSearch="filters.search"
                    :is-default-size="false"
                    ref="table"
                    @paginationSet="updatePagination">
                </merchant-data-table>
            </div>
        </template>
        <div>
            <mercur-dialog :is-open.sync="removeDialog.isOpen">
                <p>{{ removeDialog.content }}</p>
                <template #footer>
                    <mercur-button class="btn" @click.native.prevent="removeDialog.isOpen = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native.prevent="confirmRemove">Confirm</mercur-button>
                </template>
            </mercur-dialog>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import Alert from '../Alert'
import CatalogProductSettingClaims from '@/components/elements/catalog/CatalogProductSettingClaims.vue'
import CatalogProductSettingClothing from '@/components/elements/catalog/CatalogProductSettingClothing'
import CatalogProductSettingEcoCart from '@/components/elements/catalog/CatalogProductSettingEcoCart'
import CatalogProductSettingFastEditor from '@/components/elements/catalog/CatalogProductSettingFastEditor'
import CatalogProductSettingSample from '@/components/elements/catalog/CatalogProductSettingSample'
import CatalogProductSettingMongoDb from '@/components/elements/catalog/CatalogProductSettingMongoDb'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'

export default {
    name: 'CatalogProductSetting',
    components: {
        CatalogProductSettingClaims,
        CatalogProductSettingClothing,
        CatalogProductSettingEcoCart,
        CatalogProductSettingFastEditor,
        CatalogProductSettingSample,
        CatalogProductSettingMongoDb,
        MerchantDataTable,
        Pagination,
        Alert,
    },
    data () {
        return {
            isEditing: false,
            isSaving: false,
            errorMessage: null,
            isInvalidBundleError: false,
            historyValue: null,
            removeDialog: {},
            url: CONFIG.API.ROUTES.CATALOG.VARIANTS.PRODUCT_SETTINGS.LIST.replace('{productVariantId}', this.productVariantId),
            pagination: {},
            amountOfDisplayedItems: 10,
            settingTypeMap: {
                'EcoCart': 'eco_cart',
                'Clothing': 'clothing',
                'Claims': 'claims',
                'FastEditor': 'fast_editor',
                'Sample': 'sample',
                'MongoDb': 'mongo_db',
            },
            filters: {
                search: '',
            },
        }
    },
    props: {
        catalogProductSettings: {
            type: Object,
            default: () => {},
        },
        productVariantId: null,
        type: null,
        options: null,
    },
    computed: {
        mergedOptions () {
            return {
                ...this.options,
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            onClick: ({ data }) => {
                                this.triggerEdit(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/updateEditorConfiguration'),
                        },
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.triggerRemove(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/removeEditorConfiguration'),
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 10,
                cacheBlockSize: 10,
            }
        },
        isDirty () {
            return JSON.stringify(this.catalogProductSettings) !== JSON.stringify(this.historyValue)
        },
    },
    methods: {
        saveCatalogProductSettings (isEditing) {
            let url = null
            if (isEditing) {
                url = CONFIG.API.ROUTES.CATALOG.VARIANTS.PRODUCT_SETTINGS.UPDATE
                    .replace('{productVariantId}', this.productVariantId)
                    .replace('{catalogProductSettingId}', this.catalogProductSettings.catalogProductSettingId)
            } else {
                url = CONFIG.API.ROUTES.CATALOG.VARIANTS.PRODUCT_SETTINGS.CREATE
                    .replace('{productVariantId}', this.productVariantId)
            }

            const type = this.settingTypeMap[this.type]

            let catalogProductSettings = JSON.parse(JSON.stringify(this.catalogProductSettings))
            catalogProductSettings.configuration = catalogProductSettings.configuration[this.type]
            let payload = { ...catalogProductSettings, type }

            this.post(url, payload, 'Successfully saved', 'Something went wrong. Please try again.').then(() => {
                window.location.reload()
            }).catch(err => {
                this.errorMessage = err.data.exceptions[0].reason_phrase
            })
        },
        saveBundleSelection () {
            const configurations = this.catalogProductSettings.configuration[this.type]
            this.isInvalidBundleError = Object.keys(configurations).length !== Object.keys(configurations).filter((attribute) => {
                if (configurations[attribute] == null) {
                    return false
                }

                return !(typeof configurations[attribute] === 'string' && configurations[attribute].trim().length === 0)
            }).length

            if (!this.isInvalidBundleError) {
                this.saveCatalogProductSettings(this.isEditing)
                this.$set(this, 'historyValue', JSON.parse(JSON.stringify(this.catalogProductSettings)))
            }
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        triggerEdit (data) {
            this.isEditing = true
            this.catalogProductSettings.catalogProductSettingId = data.catalogProductSettingId
            this.catalogProductSettings.selectedAttributes = data.attributes
            this.catalogProductSettings.configuration[this.type] = data.configuration
        },
        triggerRemove (data) {
            this.removeDialog = {
                isOpen: true,
                content: `Are you sure you want to remove this configuration?`,
                data: data,
            }
        },
        confirmRemove () {
            const url = CONFIG.API.ROUTES.CATALOG.VARIANTS.PRODUCT_SETTINGS.REMOVE
                .replace('{productVariantId}', this.productVariantId)
                .replace('{catalogProductSettingId}', this.removeDialog.data.catalogProductSettingId)
            this.post(url, {}, 'Configuration removed').then(({ data }) => {
                this.removeDialog = {}
                this.$refs.table.refreshGrid()
                location.reload()
            })
        },
        discardChanged () {
            this.catalogProductSettings.catalogProductSettingId = null
            this.catalogProductSettings.selectedAttributes = this.resetValues(this.catalogProductSettings.selectedAttributes, [])
            this.catalogProductSettings.configuration[this.type] = this.resetValues(this.catalogProductSettings.configuration[this.type], null)
            this.isEditing = false
        },
        resetValues (object, value) {
            return Object.keys(object).reduce((acc, key) => {
                acc[key] = value
                return acc
            }, {})
        },
    },
}
</script>
<style lang="scss" scoped>
.link {
    cursor: pointer;
}

.attribute-groups {
    &__group {
        position: relative;
        border-left: 5px solid #cacaca;
        padding-left: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    &__dropzone {
        position: relative;
        margin-top: 10px;
        border: 2px dashed #acacac;
        background-color: lighten(#acacac, 40%);
        border-radius: 3px;
        padding: 4px;
        text-align: center;
    }

    &__ghost-item {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .attribute-groups__attribute-icon {
            display: none;
        }

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 11px;
            content: 'Add option to this group';
            background-color: white;
        }
    }

    &__attribute {
        cursor: grab;
    }

    &__attribute-icon {
        vertical-align: middle;
        margin-bottom: 6px;
        margin-top: 4px;
    }
}

.toggle-groups {
    margin-top: -55px;
    float: right;
}

.d-flex {
    clear: both;
}
</style>


<template>
    <div>
        <button class="btn btn-icon btn-primary text-white" @click="toggleMenu" v-if="isAuthenticated"><i class="fas fa-bars"></i></button>
        <router-link :to="defaultLink" class="logo">
            <logo fill="white"></logo>
        </router-link>
    </div>
</template>

<script>
import Logo from '@/components/assets/Logo'
import { mapGetters } from 'vuex'

export default {
    name: 'TopMenu',
    components: { Logo },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        defaultLink () {
            if (this.isAuthenticated) {
                return {
                    name: 'CatalogWrapper',
                }
            }

            return {
                name: 'LoginView',
            }
        },
    },
    methods: {
        toggleMenu () {
            this.$root.$emit('toggleMenu')
        },
    },
}
</script>

<style lang="scss" scoped>
    .logo {
        color: white !important;
        text-transform: none;
        font-weight: 600;
        margin-left: 15px;
        line-height: 14px;
    }
</style>

<template>
    <div>
        <v-title :title="title"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>{{title}}</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid mt-3">
            <div class="row" v-if="selectedShop.applicationId !== '0'">
                <div class="col-3">
                    <h3 class="c-catalog-filter__title">Filters</h3>
                    <catalog-filters
                        v-model="filterBy"
                        :overviewType="overviewType"
                        @input="getProducts"
                    ></catalog-filters>
                </div>
                <div class="col-9">
                    <div class="row">
                        <em v-if="catalogProducts && catalogProducts.length === 0">No products found</em>
                        <div
                            v-for="catalogProduct in catalogProducts"
                            :key="catalogProduct.marketplaceProductId"
                            data-test="catalogProduct"
                            class="col-3 mb-2"
                            @click="clickProduct(catalogProduct)"
                        >
                            <div class="shadow catalog-product p-0">
                                <div
                                    data-e2e="catalogProduct"
                                    class="catalog-product__image"
                                    style="background-image:url('https://dummyimage.com/400x400/cccccc/000000.png&text=Image+not+available')"
                                    :style="getCatalogProductImageStyle(catalogProduct)"
                                ></div>
                                <div
                                    class="catalog-product__title p-3"
                                    :title="getCatalogProductTitle(catalogProduct)"
                                >
                                    {{ getCatalogProductTitle(catalogProduct) }} - countries: {{ catalogProduct.availableCountries.length }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p>Please select a shop</p>
            </div>
        </div>
        <mercur-dialog
            :is-open.sync="isAddProductDialogOpen"
            :width="'900px'"
            data-e2e="catalogApplicationSelectorModal"
        >
            <form @submit.prevent="addProductToCatalog">
                <div class="c-catalog-add-dialog__content">
                    <application-selector
                        class="h-100 d-flex flex-column"
                        :active-step.sync="applicationSelectionWizard"
                        @input="addProductApplications = $event"
                        :product="addProductParams"
                    ></application-selector>
                </div>
                <mercur-spinner v-if="addProductPending"></mercur-spinner>
                <div slot="footer" v-if="addProductApplications.length">
                    <mercur-button class="btn" :disabled="addProductPending" @click.native="isAddProductDialogOpen = false">Close</mercur-button>
                    <mercur-button
                        data-e2e="catalogApplicationSelectorAddProductsButton"
                        class="btn btn-primary btn-raised"
                        type="submit"
                        :disabled="addProductPending || addProductApplications.length === 0">
                        Add products to catalog
                    </mercur-button>
                </div>
            </form>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ApplicationSelector from '../../../components/elements/catalog/ApplicationSelector'
import CatalogFilters from '../../../components/elements/catalog/CatalogFilters'

const MARKETPLACE_OVERVIEW = 'marketplace'
const CATALOG_OVERVIEW = 'catalog'

export default {
    name: 'CatalogOverview',
    components: { CatalogFilters, ApplicationSelector },
    props: {
        overviewType: {},
    },
    data () {
        return {
            applicationSelectionWizard: 0,
            catalogProducts: null,
            addProductParams: null,
            addProductApplications: [],
            addProductPending: false,
            isAddProductDialogOpen: false,
            overviewTypes: {
                MARKETPLACE_OVERVIEW,
                CATALOG_OVERVIEW,
            },
            filterBy: {},
        }
    },
    computed: {
        url () {
            if (this.overviewType === MARKETPLACE_OVERVIEW) {
                return CONFIG.API.ROUTES.CATALOG.LIST_MARKETPLACE_PRODUCTS
            }
            return CONFIG.API.ROUTES.CATALOG.LIST_CATALOG_PRODUCTS
        },
        title () {
            return this.overviewType === MARKETPLACE_OVERVIEW ? 'Marketplace' : 'Catalog'
        },
    },
    methods: {
        clickProduct (product) {
            if (this.overviewType === MARKETPLACE_OVERVIEW) {
                return this.openAddProductDialog(product)
            }

            if (product.parentCatalogProductId) {
                return this.$router.push({
                    name: 'CatalogProductView',
                    params: {
                        catalogProductId: product.parentCatalogProductId,
                        productVariantId: product.catalogProductId,
                    },
                })
            }

            this.$router.push({
                name: 'CatalogProductView',
                params: product,
            })
        },

        addProductToCatalog () {
            this.addProductPending = true

            const payload = {
                marketplaceProductId: this.addProductParams.marketplaceProductId,
                applications: this.addProductApplications,
            }

            this.post(
                CONFIG.API.ROUTES.CATALOG.ADD_PRODUCT_TO_CATALOG,
                payload,
                'Product was successfully added to the catalog',
                'Something went wrong. Please try again',
            ).then(({ data }) => {
                this.$set(this, 'isAddProductDialogOpen', false)
                this.$nextTick(() => {
                    this.$router.push({
                        name: 'CatalogProductView',
                        params: {
                            catalogProductId: data.catalogProductId,
                        },
                    })
                })
            }).finally(() => {
                this.addProductPending = false
            })
        },
        getCatalogProductTitle (catalogProduct) {
            if (!catalogProduct) {
                return
            }
            if (!catalogProduct.productContent) {
                return catalogProduct.productName
            }

            return catalogProduct.productContent.productName
        },
        getCatalogProductImageStyle (catalogProduct) {
            if (!catalogProduct || !catalogProduct.productContent || !catalogProduct.productContent.productContentPrimaryImage || !catalogProduct.productContent.productContentPrimaryImageUrl) {
                return
            }

            return {
                backgroundImage: `url(${catalogProduct.productContent.productContentPrimaryImageUrl})`,
            }
        },
        getProducts () {
            const payload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            payload.filterBy = Object.entries(this.filterBy).map(([fieldName, values]) => {
                if (fieldName === 'currentAttributes') {
                    return null
                }
                if (this.overviewType === MARKETPLACE_OVERVIEW && (fieldName === 'variantName' || fieldName === 'variantSlug')) {
                    return null
                }
                return {
                    field: fieldName,
                    operator: 'ilike',
                    value: `%${values.filter}%`,
                }
            }).filter(Boolean)
            if (this.filterBy.currentAttributes) {
                payload.currentAttributes = JSON.parse(JSON.stringify(this.filterBy.currentAttributes))
            }
            this.$set(this, 'catalogProducts', null)
            this.post(this.url, payload).then(data => {
                this.$set(this, 'catalogProducts', data.data.items)
            })
        },
        openAddProductDialog (product) {
            this.$set(this, 'addProductParams', product)
            this.$set(this, 'addProductApplications', [])
            this.$set(this, 'isAddProductDialogOpen', true)
        },
    },
    watch: {
        overviewType: {
            handler () {
                this.getProducts()
            },
        },
        selectedShop: {
            handler () {
                if (this.selectedShop.applicationId === '0') {
                    return
                }
                this.getProducts()
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
    .catalog-product {
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        &__image {
            padding-top: 100%;
            background-color: #d6d6d6;
            background-size: cover;
            background-position: center center;
        }
        &__title {
            font-size: 15px;
            font-weight: 400;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
    .c-catalog-add-dialog {
        max-width: 400px;

        &__content {
            height: 90vh;
            max-height: 500px;
            backface-visibility: hidden;
        }
    }
</style>
